//color/ui color variables
$white: #ececec;
$black: #333333;
$blue: #7aadff;
$purple: #a95cfa;

.home-title-btn {
  position: relative;
  text-transform: uppercase;
  font-family: "Akkurat-Mono", sans-serif;
  border: 0;
  background: transparent;
  outline: none;
  color: $white;
  z-index: 2;
  letter-spacing: 0.25em;
  margin: 1rem 2rem;
  padding: 1rem 2.5rem;
  font-size: 60px;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    transform: skewX(30deg);
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: -2;
  }
  &::before {
    background-color: $blue;
    top: -1rem;
    left: 0rem;
  }
  &::after {
    background-color: $purple;
    top: 1rem;
    left: 50%;
  }
  &:hover {
    &::before,
    &::after {
      top: 0;
      transform: skewx(0deg);
    }
    &::after {
      left: 0rem;
    }
    &::before {
      left: 50%;
    }
  }
}

.home-title-btn.sm {
  margin-top: 2rem;
  font-size: 24px;
}

.home-title-btn.lg {
  margin-top: 3rem;
  font-size: 60px;
}