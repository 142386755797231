@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

@font-face {
  font-family: "PlayfairDisplay-Regular";
  src: url("../public/styles/PlayfairDisplay-Regular.ttf") format("truetype");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akkurat-Mono";
  src: url("../public/styles/Akkurat-Mono.ttf") format("truetype");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.body {
  margin: 0;
  flex-grow: 1;
  display: block;

  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}

.navbar-black {
  background-color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.navbar a {
  /*padding: 15px 20px;*/
  text-decoration: none;
  font-family: Lato, sans-serif;
  color: white;
  text-transform: uppercase;
  text-align: center;
}

.navbar.sm {
  min-height: 45px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 20px;
  font-size: 1.25em;
}

.navbar.md {
  min-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
  font-size: 1.5em;
}

.navbar.lg {
  min-height: 75px;
  padding-left: 25px;
  padding-right: 25px;
  gap: 20px;
  font-size: 1.75em;
}

.body.sm {
  padding-top: 45px;
}

.body.md {
  padding-top: 60px;
}

.body.lg {
  padding-top: 75px;
}